<template>
  <div>
    <div class="flex xs12 pb-5">
      <p class="display-1 text--center mb-2">
        {{ $t("auth.reset_password_title") }}
      </p>
      <p class="display-2 text--center">{{ $t("auth.reset_password") }}</p>
    </div>

    <form v-if="validRequest && !passwordSet" @submit.prevent="onsubmit">
      <p class="mb-4">{{ $t("auth.set_password_text") }}</p>
      <va-input
        class="mb-3"
        v-model="setPassForm.email"
        type="email"
        :label="$t('auth.email')"
        :readonly="true"
      />

      <va-input
        class="mb-3"
        v-model="setPassForm.fullname"
        type="text"
        :label="$t('auth.fullname')"
        :readonly="true"
      />

      <va-input
        class="mb-3"
        v-model="setPassForm.password"
        type="password"
        :label="$t('auth.password')"
        :error="!!passErrors.length"
        :error-messages="passErrors"
      />

      <va-input
        class="mb-3"
        v-model="setPassForm.passwordAgain"
        type="password"
        :label="$t('auth.password_again')"
        :error="!!pass2Errors.length"
        :error-messages="pass2Errors"
      />

      <div class="row justify--center mt-3">
        <va-button type="submit" class="my-0">{{
          $t("auth.submit")
        }}</va-button>
      </div>
    </form>

    <div v-if="!validRequest">
      <div class="flex xs12 pb-5">
        <p class="display-1 text--center">
          {{ $t("auth.set_password_invalid") }}
        </p>
      </div>
    </div>

    <div v-if="passwordSet">
      <div class="flex xs12 pb-5">
        <p class="display-2 text--center">{{ $t("auth.password_set") }}</p>
        <p class="display-2 text--center">{{ $t("auth.close_window") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "recover-password",
  data() {
    return {
      setPassForm: {
        passwordNewConfirmCode: "",
        email: "",
        fullname: "",
        password: "",
        passwordAgain: "",
      },
      passErrors: [],
      pass2Errors: [],
      validRequest: false,
      passwordSet: false,
    };
  },
  created() {
    this.setPassForm.passwordNewConfirmCode = this.$route.params.id;
    // console.log(this.setPassForm.passwordNewConfirmCode)
    this.backend
      .getSetPasswordUser(this.setPassForm.passwordNewConfirmCode)
      .then((result) => {
        if (result === false) {
          // console.log('wrong input data')
          this.validRequest = false;
        } else {
          // console.log('got correct data')
          // console.log(result)
          this.setPassForm = result;
          this.validRequest = true;
        }
      })
      .catch((err) => {
        // console.log('error getting response')
        this.validRequest = false;
      });
  },
  computed: {
    formReady() {
      return !this.passErrors.length && !this.pass2Errors.length;
    },
  },
  methods: {
    onsubmit() {
      this.passErrors = this.setPassForm.password
        ? []
        : [this.$t("auth.password_not_present")];
      this.pass2Errors = this.setPassForm.passwordAgain
        ? []
        : [this.$t("auth.password_not_present")];
      if (
        this.setPassForm.password &&
        this.setPassForm.passwordAgain &&
        this.setPassForm.password !== this.setPassForm.passwordAgain
      ) {
        this.passErrors = [this.$t("auth.passwords_not_match")];
        this.pass2Errors = [this.$t("auth.passwords_not_match")];
      }
      if (!this.formReady) {
        // console.log('form is invalid')
        return;
      }
      this.backend
        .setPasswordUser(this.setPassForm)
        .then((result) => {
          if (result === false) {
            // console.log('error reseting password')
            this.launchToast(
              this.$t("auth.user_password_set_error"),
              "warning"
            );
          } else {
            // console.log('reset done')
            this.passwordSet = true;
          }
        })
        .catch((err) => {
          // console.log('error getting response')
          this.launchToast(this.$t("auth.user_password_set_error"), "danger");
        });
    },
    launchToast(text, col) {
      this.$vaToast.init({
        message: text,
        position: "top-left",
        duration: 4000,
        fullWidth: false,
        color: col,
      });
    },
  },
};
</script>

<style lang="scss"></style>
